@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
.nextui-button-text {
  z-index: 0 !important;
} */

.text-resize {
  font-size: calc(4vw);
  /* Adjust the base font size and scaling factor as needed */
}

@media screen(sm) {
  .text-resize {
    font-size: calc(0.85rem + 1vw); /* Adjust the base font size and scaling factor as needed */
  }
}
